var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register",style:(_vm.backgroundDiv)},[(!_vm.register)?_c('blockquote',[_c('a-col',{staticClass:"loginTitle",attrs:{"span":"24"}},[_c('img',{attrs:{"src":require("../assets/logo-h5.png")}}),_c('span',[_vm._v("翻红馆")])]),(_vm.code)?_c('a-col',{staticClass:"registerTip",attrs:{"span":"24"}},[_c('img',{attrs:{"src":_vm.imgUrl}}),_c('span',[_c('span',[_vm._v(_vm._s(_vm.userName))]),_vm._v(" 邀请您加入翻红馆，一起口播赚钱")])]):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userName',
            {
              rules: [{ required: false, message: '请输入用户名!' }],
            } ]),expression:"[\n            'userName',\n            {\n              rules: [{ required: false, message: '请输入用户名!' }],\n            },\n          ]"}],staticClass:"first-child",attrs:{"autocomplete":"off","placeholder":"输入手机号","maxLength":_vm.phoneLength}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: false, message: '请输入密码!' }],
            } ]),expression:"[\n            'password',\n            {\n              rules: [{ required: false, message: '请输入密码!' }],\n            },\n          ]"}],staticClass:"last-child",attrs:{"autocomplete":"off","placeholder":"输入验证码","maxLength":_vm.codelength}}),(_vm.time > 0)?_c('span',[_vm._v(_vm._s(_vm.time)+"s后再次获取")]):_vm._e(),(_vm.time == 0)?_c('span',{on:{"click":_vm.getCode}},[_vm._v("获取验证码")]):_vm._e()],1),(_vm.code)?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {
              rules: [{ required: false }],
            } ]),expression:"[\n            'code',\n            {\n              rules: [{ required: false }],\n            },\n          ]"}],staticClass:"end-child",attrs:{"autocomplete":"off","placeholder":"邀请码","disabled":true}}),_c('span',{staticClass:"inviteCode"},[_vm._v(_vm._s(_vm.code))])],1):_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'inviteCode',
            {
              rules: [{ required: false, message: '请输入邀请码!' }],
            } ]),expression:"[\n            'inviteCode',\n            {\n              rules: [{ required: false, message: '请输入邀请码!' }],\n            },\n          ]"}],staticClass:"end-child",attrs:{"autocomplete":"off","placeholder":"输入邀请码","maxLength":_vm.codelength}})],1),_c('a-form-item',{staticClass:"button"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 快速注册 ")])],1),_c('router-link',{staticClass:"gotoLogin",attrs:{"tag":"a","to":"/loginApp"}},[_vm._v("已有账号，点此登录")])],1)],1):_vm._e(),(_vm.register)?_c('blockquote',[_c('a-col',{staticClass:"successTitle",attrs:{"span":"24"}},[_c('img',{attrs:{"src":require("../assets/success-h5.png")}}),_c('span',[_vm._v("注册成功")])]),_c('a-button',{staticClass:"login-form-button download",attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){return _vm.download('Android')}}},[_vm._v(" Android版本下载 ")]),_c('a-button',{staticClass:"login-form-button download",attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){return _vm.download('iOS')}}},[_vm._v(" iOS版本下载 ")])],1):_vm._e(),(_vm.showToast)?_c('div',{staticClass:"showToast"},[_c('div',{staticClass:"showToast_con"},[_vm._v(_vm._s(_vm.showToast))])]):_vm._e(),(_vm.success_tip)?_c('div',{staticClass:"success_tip",on:{"click":_vm.closeTip}},[_c('img',{attrs:{"src":require("../assets/register-tip.png")}})]):_vm._e(),(_vm.download_tip)?_c('div',{staticClass:"download_tip"},[_c('div',{staticClass:"download_con"},[_c('div',{staticClass:"title"},[_vm._v("提示信息")]),_c('div',{staticClass:"content"},[_vm._v(" iOS版本正在审核中，您可以在网页登录后，邀请好友加入 ")]),_c('div',{staticClass:"button"},[_c('a',{on:{"click":_vm.hideTip}},[_vm._v("取消")]),_c('router-link',{staticClass:"gotoLogin",attrs:{"tag":"a","to":"/loginApp"}},[_vm._v("去登录")])],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }